<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "dashboard",
  beforeMount() {
    this.$router.push({ name: "home", params: { id: "8445558123450" } });
  },
  async mounted() {},
  data() {
    return {
      headers: [
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "state",
        },
        { text: "Customer", value: "company_format.company.name" },
        { text: "Date", value: "formattedDate" },
        { text: "TagRef", value: "company_format.tag_type.reference" },
        { text: "Quantity", value: "total_quantity" },
        { text: "Action", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchArticles: "dashboard/fetchArticles",
    }),
  },
  computed: {
    ...mapGetters({
      articles: "dashboard/getArticle",
    }),
  },
};
</script>

<style></style>
